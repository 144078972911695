<template>
  <div class="container_box">
    <Tabs v-model="orderTableStatus" :animated="false" class="tabs_styles" @on-click="changeTab">
      <TabPane v-for="item in stockTabList" :label="item.title" :name="item.value"></TabPane>
    </Tabs>
    <!--头部筛选区域-->
    <div class="filter_box">
      <Form ref="pageParams" :model="pageParams" :label-width="labelWidth" @submit.native.prevent>
        <Row type="flex" :gutter="gutterItem">
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol" v-if="orderTableStatus === '0'">
            <FormItem :label="$t('key1003302')" prop="packageOrderStatusList">
              <Select v-model="pageParams.packageOrderStatusList" transfer multiple :placeholder="$t('key1003523')">
                <Option v-for="item in stockOrderStatusList" :key="item.value" :value="item.value">{{ item.title }}</Option>
              </Select>
            </FormItem>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol"
            v-if="['0','1', '2'].includes(orderTableStatus)">
            <FormItem :label="$t('key1003520')" prop="outPackageOrderStatusList">
              <Select v-model="pageParams.outPackageOrderStatusList" transfer multiple :placeholder="$t('key1003525')">
                <Option v-for="item in deliveryOrderStatusList" :key="item.value" :value="item.value">{{ item.title }}</Option>
              </Select>
            </FormItem>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <FormItem :label="$t('key1003401')" prop="outboundTypes">
              <Select v-model="pageParams.outboundTypes" transfer multiple :placeholder="$t('key1003527')">
                <Option v-for="item in deliveryOrderTypeList" :key="item.value" :value="item.value">{{ item.title }}</Option>
              </Select>
            </FormItem>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <dropDownDateTime
              ref="dropDownDateTime"
              :dropDownTimeList="dropDownTimeList"
              :defaultSelectValue="defaultSelectTimeValue"
              @changeInput="changeTimeInput"
              @selectTime="selectTime"
              @resetTime="resetTime">
            </dropDownDateTime>
          </Col>
          <Col :xxl="fourItemCol" :xl="threeItemCol" :lg="twoItemCol" :md="oneItemCol">
            <dropDownFilter
              ref="dropDownFilter"
              inputType="textarea"
              :selectStyle="{width: '110px'}"
              :dropDownInputList="dropDownInputList"
              :defaultSelectValue="defaultSelectValue"
              @changeInput="changeInput"
              @updateInputValue="updateInputValue">
            </dropDownFilter>
          </Col>
          <Col style="width: 200px;" push="1">
            <Button @click="reset" icon="md-refresh" class="mr12">{{ $t('key1000094') }}</Button>
            <Button type="primary" @click="search" icon="md-search">{{ $t('key1000269') }}</Button>
          </Col>
        </Row>
      </Form>
    </div>
    <!--功能按钮操作区域-->
    <div class="operation_box">
      <!--发起备货-->
      <Button type="primary" class="mr15" v-if="getPermission('packageInfo_createStock')"
        @click="$router.push('/initiateStocking')">{{ $t('key1005430') }}</Button>
      <!--批量发货-->
      <Dropdown transfer @on-click="bulkShipmentBtn" class="mr15"
        v-if="['0','1'].includes(orderTableStatus) && isWarehouseManagement === 'N' && getPermission('wmsPickupOrder_batchDeliver')">
        <Button type="primary">
          <span>{{ $t('key1005431') }}</span>
          <Icon type="ios-arrow-down"></Icon>
        </Button>
        <DropdownMenu slot="list">
          <DropdownItem name="1">{{ $t('key1005432') }}</DropdownItem>
          <DropdownItem name="2">{{ $t('key1005433') }}</DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <!--导出数据-->
      <Dropdown transfer @on-click="changeExportBtn" class="mr15"
        v-if="getPermission('packageInfo_exportPackageInfoStockOrder')">
        <Button>
          <span>{{ $t('key1002229') }}</span>
          <Icon type="ios-arrow-down"></Icon>
        </Button>
        <DropdownMenu slot="list">
          <DropdownItem name="1">{{ $t('key1002230') }}</DropdownItem>
          <DropdownItem name="2">{{ $t('key1002231') }}</DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <!--打印配货清单-->
      <Button class="mr15" v-if="getPermission('packageInfo_printPrepareGoodsStock')" @click="printDistributionBtn">{{ $t('key1004151') }}</Button>
      <!--打印YMS条码-->
      <Dropdown transfer @on-click="printBarcodeBtn" class="mr15">
        <Button>
          <span>{{ $t('key1000825') + configCharacters + $t('key1000827') }}</span>
          <Icon type="ios-arrow-down"></Icon>
        </Button>
        <DropdownMenu slot="list">
          <DropdownItem v-for="item in printBarcodeList" :name="item.name"
            v-if="getPermission(item.permissionKey)">{{ item.title }}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </div>
    <!--列表区域-->
    <div class="table_box">
      <Table
        ref="selection"
        highlight-row
        :loading="tableLoading"
        border
        max-height="530"
        :columns="tableColumns"
        :data="tableData"
        @on-selection-change="tableChange">
      </Table>
      <Page
        v-if="!tableLoading"
        :total="total"
        @on-change="changePage"
        show-total
        :page-size="pageParams.pageSize"
        show-elevator
        :current="pageParams.pageNum"
        show-sizer
        @on-page-size-change="changePageSize"
        placement="top"
        :page-size-opts="pageArray">
      </Page>
    </div>
    <!--导出数据字段弹窗-->
    <exportFieldsModal
      ref="exportFields"
      :exportFieldsTitle="$t('key1002229')"
      paramKeyColums="supplierPackageInfoOrderStockExportColumns"
      :customFieldExportList="customFieldExportList"
      @exportFieldsDataBtn="exportFieldsDataBtn">
    </exportFieldsModal>
    <!--配货清单格式弹窗-->
    <distributionListFormatModal ref="distributionListFormat" @updateSelect="updateSelect"></distributionListFormatModal>
    <!--扫描换单-->
    <scanOrderChangeModal ref="scanOrderChange"></scanOrderChangeModal>
    <!--标记发货弹窗-->
    <logisticsInfoModal ref="logisticsInfo" @updateData="printBoxBtn"></logisticsInfoModal>
    <!--打印箱唛弹窗-->
    <printCaseMarkModal
      ref="printTalg"
      :apiUrl="apiUrl"
      :printUpdate="true"
      service="yms-supplier-service"
      typeTalg="packingManage"
      @printUpdateData="search()">
    </printCaseMarkModal>
    <!--修改备货数量弹窗-->
    <modifyStockQuantityModal ref="modifyStockQuantity" @updateData="pageParamsStatus = true"></modifyStockQuantityModal>
  </div>
</template>

<script>
import { alias1bddc4d174174d47a6dfb0437dace856 } from '@/customFolder/customVueAlias.js';

import printCaseMarkModal from "@/components/common/printCaseMarkModal.vue";
import distributionListFormatModal from "@/views/supplier/components/supplierOrder/distributionListFormatModal.vue";
import logisticsInfoModal from "@/components/common/logisticsInfoModal.vue";
import exportFieldsModal from "@/components/common/exportFieldsModal.vue";
import scanOrderChangeModal from "@/views/supplier/components/supplierOrder/scanOrderChangeModal.vue";
import dropDownFilter from "@/components/common/dropDownFilter.vue";
import Mixin from "@/components/mixin/common_mixin";
import api from "@/api";
import dropDownDateTime from "@/components/common/dropDownDateTime.vue";
import modifyStockQuantityModal from "@/views/supplier/components/supplierOrder/modifyStockQuantityModal.vue";
import {DELIVERYORDERTYPE} from "@/utils/enum";
import {pageJump, setConfigCharacters} from "@/utils/common";

export default {
  name: "stockList",
  mixins: [Mixin],
  data() {
    let v = this;
    return {
      total: 0,
      pageParamsStatus: false,
      orderTableStatus: '0',
      pageParams: {
        packageOrderStatusList: [], // 备货单状态 1:待发货 2:已发货 21:已入库 3:已分拣(YMS已收货) 4:已取消
        outPackageOrderStatusList: [], // 出库单状态 1:待发货/待拣货 2:待包装 3:待出库 4:已出库 7:已作废 8:已装箱
        outboundTypes: [], // 出库单类型 (1:销售出库 2:自有仓备货(云卖中心仓备货) 3:三方仓备货(第三方仓备货))
        packageCodeList: '', // 备货单号
        skuList: '', //sku
        ymsSkuList: '', // ymsSkuList
        startCreatedTime: null, // 开始创建时间
        endCreatedTime: null, // 结束创建时间
        startDespatchTime: null,  // 开始发货时间
        endDespatchTime: null, // 结束发货时间
        startStorageTime: null, // 开始入库时间
        endStorageTime: null, // 结束入库时间
        startCancleTime: null, // 开始取消时间
        endCancleTime: null, // 结束取消时间
        pageNum: 1,
        pageSize: 15,
      },
      tableData: [],
      tableColumns: [],
      stockTabList: [
        {value: '0', title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000095')},
        {value: '1', title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001182')},
        {value: '2', title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001169')},
        {value: '3', title: `${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1003529')}`},
        {value: '4', title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001163')},
      ],
      stockOrderStatusList: [
        {value: 1, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001182')},
        {value: 2, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001169')},
        {value: 3, title: `${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1003529')}`},
        {value: 4, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001163')},
      ],
      deliveryOrderTypeList: DELIVERYORDERTYPE,
      deliveryOrderStatusList: [],
      exportType: '1',
      customFieldExportList: [
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000596'),
          titleKey: 'basicInfoColums',
          selectList: [], // 选中导出字段的key
          checkBoxList: [
            {paramKey: 'packageCode', paramValue: alias1bddc4d174174d47a6dfb0437dace856.t('key1003531')},
            {paramKey: 'supplierMerchantId', paramValue: alias1bddc4d174174d47a6dfb0437dace856.t('key1000784')},
            {paramKey: 'packageOrderStatus', paramValue: alias1bddc4d174174d47a6dfb0437dace856.t('key1000498')},
            {paramKey: 'totalStockQuantity', paramValue: alias1bddc4d174174d47a6dfb0437dace856.t('key1003405')},
            {paramKey: 'actualSortingQuantity', paramValue: alias1bddc4d174174d47a6dfb0437dace856.t('key1003406')},
            {paramKey: 'pickupOrderNumber', paramValue: alias1bddc4d174174d47a6dfb0437dace856.t('key1002750')},
            {paramKey: 'outPackageOrderStatus', paramValue: alias1bddc4d174174d47a6dfb0437dace856.t('key1002402')},
            {paramKey: 'outboundType', paramValue: alias1bddc4d174174d47a6dfb0437dace856.t('key1002435')},
            {paramKey: 'createdTime', paramValue: alias1bddc4d174174d47a6dfb0437dace856.t('key1001514')},
            {paramKey: 'despatchTime', paramValue: alias1bddc4d174174d47a6dfb0437dace856.t('key1003536')},
            {paramKey: 'storageTime', paramValue: alias1bddc4d174174d47a6dfb0437dace856.t('key1002902')},
            {paramKey: 'rehandleTime', paramValue: alias1bddc4d174174d47a6dfb0437dace856.t('key1003537')},
          ]
        }
      ],
      printBarcodeList: [
        {name: '1', permissionKey: 'packageInfo_appendYmsSkuExpressBillStcok', title: alias1bddc4d174174d47a6dfb0437dace856.t('key1005436')},
        {name: '2', permissionKey: 'packageInfo_appendPackageInfoStockExpressBill', title: alias1bddc4d174174d47a6dfb0437dace856.t('key1005437')},
      ],
      apiUrl: api.put_wmsPickupOrder_printWmsPickupOrder,
      recordParams: {}, // 记录当前筛选条件的参数
      dropDownTimeList: [],
      defaultSelectTimeValue: 1,
      dropDownInputList: [ // 备货单号、SKU、YMSSKU
        {title: alias1bddc4d174174d47a6dfb0437dace856.t('key1003531'), value: 1, placeholder: alias1bddc4d174174d47a6dfb0437dace856.t('key1003532'), parameterKey: 'packageCodeList'},
        {title: 'SKU', value: 2, placeholder: alias1bddc4d174174d47a6dfb0437dace856.t('key1003141'), parameterKey: 'skuList'},
        {title: `${setConfigCharacters}SKU`, value: 3, placeholder: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1000287')}${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1003530')}`, parameterKey: 'ymsSkuList'}
      ],
      defaultSelectValue: 1,
    }
  },
  computed: {
    filterKeyList() {
      return this.dropDownInputList.map((item) => {
        return item.parameterKey
      });
    },
    // 是否开启仓储管理配置
    isWarehouseManagement() {
      return this.$store.state.isWarehouseManagement;
    }
  },
  created() {
    this.setDefaultTimerParams('three_months', 1);
    this.search();
  },
  methods: {
    // 重置数据
    reset() {
      let v = this;
      v.$refs['pageParams'].resetFields();
      v.changeInput(1);
      v.changeTimeInput(1);
      v.selectTableData = [];
      v.setDefaultTimerParams('three_months', 1);
      v.getList();
    },
    // 查询按钮
    search() {
      this.pageParams.pageNum = 1;
      this.getList();
    },
    // 列表查询的方法
    getList() {
      let v = this;
      v.selectTableData = [];
      v.total = 0;
      v.tableData = [];
      v.optionBtnWidth = [];
      if (v.getPermission('packageInfo_queryForStock')) {
        v.tableLoading = true;
        v.axios.post(api.post_packageInfo_queryForStock, v.handleParameter()).then(response => {
          v.recordParams = v.handleParameter();
          if (response.data.code === 0) {
            let data = response.data.datas;
            if (data) {
              v.tableData = data.list || [];
              v.total = data.total;
            }
            v.$nextTick(() => {
              v.tableLoading = false;
            });
          } else {
            v.tableLoading = false;
          }
        });
      } else {
        v.gotoError();
      }
    },
    // 切换Tab
    changeTab(name) {
      let v = this;
      v.$refs['pageParams'].resetFields();
      v.changeInput(1);
      v.changeTimeInput(1);
      v.selectTableData = [];
      v.pageParams.packageOrderStatusList = name === '0' ? [] : [Number(name)];
      v.setDefaultTimerParams('three_months', 1);
      v.pageParams.pageNum = 1;
      v.pageParamsStatus = true;
    },
    // 勾选列表数据
    tableChange(data) {
      this.selectTableData = [];
      if (data) {
        this.selectTableData = data;
      }
    },
    // 批量发货
    bulkShipmentBtn(value) {
      let v = this;
      let pageParamsObj = {};
      v.getSupplierWarehouseSetting(true, 'operationManage').then((val) => {
        if (val) {
          let talg = true;
          if (value === '1') {
            if (v.selectTableData.length <= 0) {
              v.$Message.warning(alias1bddc4d174174d47a6dfb0437dace856.t('key1005438'));
              talg = false;
            }
          } else {
            pageParamsObj = v.recordParams;
            if (v.tableData.length <= 0) {
              v.$Message.warning(alias1bddc4d174174d47a6dfb0437dace856.t('key1005439'));
              talg = false;
            }
          }
          // 出库单状态：待发货  备货状态：，待发货可以操作执行发货
          let packageStatusTalg = v.selectTableData.every((item) => {
            return item.outPackageOrderStatus === 1
          })
          if (!packageStatusTalg) {
            v.$Message.warning(alias1bddc4d174174d47a6dfb0437dace856.t('key1005440'));
            talg = false;
          }
          let packageOrderStatusTalg = v.selectTableData.every((item) => {
            return item.packageOrderStatus === 1
          })
          if (!packageOrderStatusTalg) {
            v.$Message.warning(alias1bddc4d174174d47a6dfb0437dace856.t('key1005441'));
            talg = false;
          }
          talg = !!(packageOrderStatusTalg && packageStatusTalg);
          if (talg) {
            if (value === '1') {
              pageParamsObj = {
                endCreatedTime: null,
                startCreatedTime: null,
                outPackageOrderStatusList: [],
                packageCodes: v.selectTableData.map((item) => {
                  return item.packageCode
                }),
                packageOrderStatusList: [],
                outboundTypes: [1],
                skuList: [],
                pageNum: 1,
                pageSize: 100
              }
            } else {
              pageParamsObj = v.recordParams;
            }
            v.$refs['logisticsInfo'].initLogisticsInfo(null, 'markShipment', pageParamsObj);
          }
        }
      })
    },
    // 参数处理
    handleParameter() {
      let v = this;
      let params = Object.assign({}, v.pageParams);
      for (let key in params) {
        if (v.filterKeyList.includes(key)) {
          params[key] = v.sepCommasFn(params[key], [',', '，', '\n']);
        }
      }
      return params;
    },
    // 选择导出数据类型  1 按选中导出 2 按查询结果导出
    changeExportBtn(value) {
      this.exportType = value;
      if (value === '1') {
        if (this.selectTableData.length <= 0) {
          this.$Message.warning(alias1bddc4d174174d47a6dfb0437dace856.t('key1002232'));
          return false;
        }
      } else {
        if (this.tableData.length <= 0) {
          this.$Message.warning(alias1bddc4d174174d47a6dfb0437dace856.t('key1002233'));
          return false;
        }
      }
      this.$refs['exportFields'].getExportFieldsSetting();
    },
    // 导出数据
    exportFieldsDataBtn(obj) {
      let v = this;
      let query = v.handleParameter();
      if (v.exportType === '1') {
        query.packageIds = v.selectTableData.map((item) => {
          return item.packageId;
        });
      } else {
        query.packageIds = [];
      }
      v.$refs['exportFields'].saveExportFieldsSetting().then((data) => {
        if (data) {
          v.axios.post(api.post_packageInfo_exportPackageInfoStockOrder, query,
            {loading: true, loadingText: alias1bddc4d174174d47a6dfb0437dace856.t('key1000212')}).then(res => {
            if (res.data.code === 0) {
              v.$refs['exportFields'].exportFieldsModal = false;
              let taskNumber = res.data.datas;
              setTimeout(() => {
                pageJump('/supplier.html#/exportTask?operateCode=' + taskNumber, '/yms-supplier-service', '_blank');
              }, 30)
            }
          });
        }
      });
    },
    // 打印配货清单
    printDistributionBtn() {
      let talg = this.tableData.length > 0 && this.selectTableData.length > 0;
      if (talg) {
        this.$refs['distributionListFormat'].initDistributionListFormat(this.selectTableData, 'stockList');
      } else {
        this.$Message.warning(alias1bddc4d174174d47a6dfb0437dace856.t('key1005442'));
        return false;
      }
    },
    // 重置勾选数据的状态
    updateSelect() {
      this.$refs['selection'].selectAll(false);
      this.selectTableData = [];
    },
    // 打印YMS条码
    printBarcodeBtn(value) {
      let v = this;
      let talg = true;
      if (value === '1') {
        this.$refs['scanOrderChange'].scanOrderChangeModal = true;
        this.$refs['scanOrderChange'].scanOrderType = 'stockList';
      } else {
        if (v.selectTableData.length <= 0) {
          v.$Message.warning(alias1bddc4d174174d47a6dfb0437dace856.t('key1005443'));
          talg = false;
        } else {
          let outboundTypeTalg = v.selectTableData.every((item) => {
            return item.outboundType === 2
          });
          if (!outboundTypeTalg) {
            v.$Message.warning(`${alias1bddc4d174174d47a6dfb0437dace856.t('key1005434')}${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1005435')}`);
            talg = false;
          }
        }
        if (v.tableData.length <= 0) {
          v.$Message.warning(alias1bddc4d174174d47a6dfb0437dace856.t('key1005444'));
          talg = false;
        }
        if (talg) {
          v.handleOrderBarcode();
        }
      }
    },
    // 批量打印订单条码
    handleOrderBarcode() {
      let v = this;
      let packageIds = [];
      v.selectTableData.map((item) => {
        packageIds.push({packageId: item.packageId});
      })
      v.axios.post(api.post_packageInfo_appendPackageInfoOrderStockExpressBill, packageIds,
        {loading: true, loadingText: alias1bddc4d174174d47a6dfb0437dace856.t('key1000212')}).then(res => {
        if (res.data.code === 0) {
          v.updateSelect();
          let data = res.data.datas;
          if (data) {
            v.commonPrint(data, '1');
          } else {
            v.$Message.warning(alias1bddc4d174174d47a6dfb0437dace856.t('key1003067'));
            return false;
          }
        }
      });
    },
    // 操作批量发货之后，打印箱唛
    printBoxBtn(data) {
      this.$refs['printTalg'].getPrintData(data.wmsPickupOrderId)
    },
    // 获取选中的时间
    selectTime(data) {
      let v = this;
      let time = data.date;
      let timeList = v.defaultTimePeriod(time);
      let current = data.value;
      v.dropDownTimeList.map((item) => {
        if (item.value === current) {
          let startTimeKey = item.parameterKey[0];
          let endTimeKey = item.parameterKey[1];
          v.$nextTick(() => {
            v.$refs['dropDownDateTime'].timeList = v.defaultTimePeriod(time, false);
          });
          v.pageParams[startTimeKey] = v.$uDate.getUniversalTime(new Date(timeList[0]).getTime(), 'fulltime');
          v.pageParams[endTimeKey] = v.$uDate.getUniversalTime(new Date(timeList[1]).getTime(), 'fulltime');
        }
      });
    },
    // 改变默认时间筛选下拉选项的值
    changeTimeInput(value) {
      this.defaultSelectTimeValue = value;
      this.$refs['dropDownDateTime'].timeList = [];
      this.dropDownTimeList.map((item) => {
        let startTimeKey = item.parameterKey[0];
        let endTimeKey = item.parameterKey[1];
        this.pageParams[startTimeKey] = null;
        this.pageParams[endTimeKey] = null;
      });
    },
    // 重置选中的时间
    resetTime(value) {
      this.dropDownTimeList.map((item) => {
        if (item.value === value) {
          let startTimeKey = item.parameterKey[0];
          let endTimeKey = item.parameterKey[1];
          this.pageParams[startTimeKey] = null;
          this.pageParams[endTimeKey] = null;
        }
      });
    },
    // 获取下拉选项筛选条件的值
    updateInputValue(data) {
      this.pageParams[data.key] = data.value;
    },
    // 改变默认下拉选项的值
    changeInput(value) {
      this.defaultSelectValue = value;
      this.$refs.dropDownFilter.inputValue = '';
      this.dropDownInputList.map((item) => {
        if (Object.keys(this.pageParams).includes(item.parameterKey)) {
          this.pageParams[item.parameterKey] = '';
        }
      });
    },
    // 处理列表字段
    handleColumns(val) {
      let v = this;
      v.optionBtnWidth = [];
      let columns = [
        {
          type: 'selection',
          width: 60,
          fixed: 'left',
          key: 'idx',
          align: 'center'
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1003531'),
          key: 'packageCode',
          fixed: 'left',
          align: 'left',
          minWidth: 150,
          render(h, params) {
            return h('Alink', {
              props: {
                linkName: params.row.packageCode,
                styles: {
                  justifyContent: 'flex-start'
                }
              },
              on: {
                AlinkBtn: () => {
                  const {href} = v.$router.resolve({
                    path: '/stockOrderDetails',
                    query: {
                      packageId: params.row.packageId
                    }
                  });
                  window.open(href, '_blank');
                }
              }
            });
          }
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000784'),
          key: 'merchantId',
          align: 'left',
          minWidth: 130
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1003533'),
          key: 'packageOrderStatus',
          align: 'left',
          minWidth: 140,
          render(h, params) {
            let text = '-';
            let orderStatus = params.row.packageOrderStatus;
            v.stockOrderStatusList.map((item) => {
              if (item.value === orderStatus) {
                text = item.title;
              }
            });
            return h('div', text);
          }
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1003405'),
          key: 'totalStockQuantity',
          align: 'left',
          minWidth: 160
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1003406'),
          key: 'actualSortingQuantity',
          align: 'left',
          minWidth: 160,
          render(h, params) {
            let actualSortingQuantity = params.row.actualSortingQuantity;
            let packageOrderStatus = params.row.packageOrderStatus; // 3 YMS已收货
            let text = packageOrderStatus === 3 ? actualSortingQuantity : '-';
            return h('span', text)
          }
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1002750'),
          key: 'pickupOrderNumber',
          align: 'left',
          minWidth: 160
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1002402'),
          key: 'outPackageOrderStatus',
          align: 'left',
          minWidth: 120,
          render(h, params) {
            let text = '-';
            let outPackageOrderStatus = params.row.outPackageOrderStatus;
            v.deliveryOrderStatusList.map((item) => {
              if (item.value === outPackageOrderStatus) {
                text = item.title;
              }
            })
            return h('span', text);
          }
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1002435'),
          key: 'outboundType',
          align: 'left',
          minWidth: 150,
          render(h, params) {
            let text = '-';
            let outboundType = params.row.outboundType;
            v.deliveryOrderTypeList.map((item) => {
              if (item.value === outboundType) {
                text = item.title;
              }
            });
            return outboundType === 4 ? h('div', {class: 'flex flex-align-center'}, [
              h('span', {class: 'mr4'}, text),
              h('Tooltip', {
                props: {
                  transfer: true,
                  maxWidth: 300,
                  content: `${alias1bddc4d174174d47a6dfb0437dace856.t('key1003190')}${setConfigCharacters}${alias1bddc4d174174d47a6dfb0437dace856.t('key1003191')}`
                },
              }, [
                h('Icon', {
                  props: {
                    type: 'md-help-circle',
                    size: 16
                  },
                  style: {
                    cursor: 'pointer'
                  }
                })
              ])
            ]) : h('span', text);
          }
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001514'),
          key: 'createdTime',
          align: 'left',
          minWidth: 170,
          render(h, params) {
            return h('span', params.row.createdTime ? v.$uDate.dealTime(params.row.createdTime, 'fulltime') : '-');
          }
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1003536'),
          key: 'despatchTime',
          align: 'left',
          minWidth: 170,
          render(h, params) {
            return h('span', params.row.despatchTime ? v.$uDate.dealTime(params.row.despatchTime, 'fulltime') : '-');
          }
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1002902'),
          key: 'storageTime',
          align: 'left',
          minWidth: 170,
          render(h, params) {
            return h('span', params.row.storageTime ? v.$uDate.dealTime(params.row.storageTime, 'fulltime') : '-');
          }
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1003537'),
          key: 'rehandleTime',
          align: 'left',
          minWidth: 170,
          render(h, params) {
            let orderStatus = params.row.orderStatus; // 5 供应商订单已取消
            if (['0', '4'].includes(v.orderTableStatus) && orderStatus === 5) {
              return h('span', params.row.rehandleTime ? v.$uDate.dealTime(params.row.rehandleTime, 'fulltime') : '-');
            } else {
              return h('span', '-');
            }
          }
        },
        {
          title: alias1bddc4d174174d47a6dfb0437dace856.t('key1000278'),
          width: 210,
          key: 'options',
          fixed: 'right',
          align: 'left',
          render(h, params) {
            let outboundType = params.row.outboundType;
            let packageCode = params.row.packageCode;
            let packageId = params.row.packageId;
            let packageOrderStatus = params.row.packageOrderStatus;
            let outPackageOrderStatus = params.row.outPackageOrderStatus;
            return h('optsButtonList', {
              props: {
                operationList: [ // 备货单状态是待发货才可以修改数量
                  {
                    text: alias1bddc4d174174d47a6dfb0437dace856.t('key1005425'),
                    show: packageOrderStatus === 1 && v.getPermission('packageInfo_upatePackageGoodsQuantity'),
                    clickFn: () => {
                      v.$refs['modifyStockQuantity'].getModifyStockData(packageCode, packageId);
                    }
                  },
                  {
                    text: alias1bddc4d174174d47a6dfb0437dace856.t('key1005445'),
                    show: [2, 3].includes(outboundType) && ![7, 8].includes(outPackageOrderStatus) &&
                      v.getPermission('packageInfo_obsoletePackageStock'),  // 云卖中心仓备货、三方仓备货且出库单状态是已装箱前都可以取消备货
                    clickFn: () => {
                      v.$Modal.confirm({
                        title: alias1bddc4d174174d47a6dfb0437dace856.t('key1005445'),
                        content: alias1bddc4d174174d47a6dfb0437dace856.t('key1005446'),
                        okText: alias1bddc4d174174d47a6dfb0437dace856.t('key1005445'),
                        cancelText: alias1bddc4d174174d47a6dfb0437dace856.t('key1000097'),
                        onOk: () => {
                          v.cancelStockingBtn(packageId);
                        },
                        onCancel: () => {
                        }
                      });
                    }
                  }
                ]
              },
              on: {
                setWidth(value) {
                  if (value) {
                    v.optionBtnWidth.push(value);
                    params.column.width = Math.max(...v.optionBtnWidth);
                    v.$forceUpdate();
                  }
                }
              }
            });
          }
        }
      ];
      // 0 全部、1 待发货、2 已发货、3 YMS已收货、4 已取消
      let newColumns = [];
      let hideKeyList = [];
      let obj = {
        '1': ['actualSortingQuantity', 'pickupOrderNumber', 'despatchTime', 'storageTime', 'updatedTime'],
        '2': ['actualSortingQuantity', 'storageTime', 'updatedTime'],
        '3': ['updatedTime'],
        '4': ['actualSortingQuantity', 'pickupOrderNumber', 'despatchTime', 'storageTime']
      }
      hideKeyList = obj[val] || [];
      newColumns = columns.filter((item) => {
        return !hideKeyList.includes(item.key)
      });
      v.tableColumns = newColumns;
    },
    // 取消备货
    cancelStockingBtn(packageId) {
      let v = this;
      let packageIds = [packageId];
      v.axios.put(api.post_packageInfo_obsoletePackageStock, packageIds, {loading: true, loadingText: alias1bddc4d174174d47a6dfb0437dace856.t('key1000212')}).then(response => {
        if (response.data.code === 0) {
          v.$Message.success(alias1bddc4d174174d47a6dfb0437dace856.t('key1000084'));
          v.pageParamsStatus = true;
        }
      });
    },
    // 处理状态数据
    handleStatusData(value, val) {
      // 出库状态
      this.deliveryOrderStatusList = [];
      this.dropDownTimeList = [];
      this.handleColumns(value);
      let isWarehouseManagement = val || this.isWarehouseManagement;
      let title = isWarehouseManagement === 'Y' ? alias1bddc4d174174d47a6dfb0437dace856.t('key1001156') : alias1bddc4d174174d47a6dfb0437dace856.t('key1001182');
      let statusList = [
        {value: 1, title: title},
        {value: 2, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001160')},
        {value: 3, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001276')},
        {value: 4, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001146')},
        {value: 5, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001155')},
        {value: 6, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001277')},
        {value: 7, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001173')},
        {value: 8, title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001168')},
      ];
      // 时间
      let timeList = [
        {title: alias1bddc4d174174d47a6dfb0437dace856.t('key1001514'), value: 1, parameterKey: ['startCreatedTime', 'endCreatedTime']},
        {title: alias1bddc4d174174d47a6dfb0437dace856.t('key1003536'), value: 2, parameterKey: ['startDespatchTime', 'endDespatchTime']},
        {title: alias1bddc4d174174d47a6dfb0437dace856.t('key1002902'), value: 3, parameterKey: ['startStorageTime', 'endStorageTime']},
        {title: alias1bddc4d174174d47a6dfb0437dace856.t('key1003537'), value: 4, parameterKey: ['startCancleTime', 'endCancleTime']},
      ];
      let values = {
        statusObj: {
          '0': [1, 2, 3, 4, 7, 8],
          '1': [1, 2, 3],
          '2': [8],
          '3': [4],
          '4': [7]
        },
        timeObj: {
          '0': [1, 2, 3, 4],
          '1': [1],
          '2': [1, 2],
          '3': [1, 2, 3],
          '4': [1, 4]
        }
      }
      statusList.map((item) => {
        if (values.statusObj[value]) {
          if (values.statusObj[value].includes(item.value)) {
            this.deliveryOrderStatusList.push(item);
          }
        }
      });
      timeList.map((item) => {
        if (values.timeObj[value]) {
          if (values.timeObj[value].includes(item.value)) {
            this.dropDownTimeList.push(item);
          }
        }
      });
    }
  },
  watch: {
    pageParamsStatus(value) {
      let v = this;
      if (value) {
        v.getList();
        v.pageParamsStatus = false;
      }
    },
    // 监听tab栏切换
    'orderTableStatus': {
      handler(value) {
        this.handleStatusData(value)
      },
      immediate: true,
      deep: true
    },
    isWarehouseManagement: {
      handler(val) {
        this.handleStatusData(this.orderTableStatus, val);
      },
      deep: true
    }
  },
  components: {
    dropDownDateTime,
    dropDownFilter,
    scanOrderChangeModal,
    exportFieldsModal,
    logisticsInfoModal,
    distributionListFormatModal,
    printCaseMarkModal,
    modifyStockQuantityModal
  }
}
</script>

<style scoped>

</style>